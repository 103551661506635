import React from "react"
import { injectIntl } from "gatsby-plugin-intl"

import MenuUtils from "./../../utils"
import Page from "./../../../../../model/node/page"
import Link from "./../../../Link"
import kebabCase from "lodash.kebabcase"

const ComponentStateless = (props) => {
    const { intl } = props
    const t = intl.formatMessage;

    const nid = props.nid || null;
    const exclude = props.exclude || [];
    const displaySectionShortDescription = (props.displaySectionShortDescriptionForParentNodeId && props.displaySectionShortDescriptionForParentNodeId.includes(nid)) || false;
    const aNodeMenuItemSubpages = MenuUtils.getSubpagesByNodeId(nid, intl.locale, exclude);

    return (
        <React.Fragment>
            <div className="_wp-block-soft4net-container-block subpages">
                <div className="wp-block-soft4net-container-block py-0 goto-scroll no-icons">
                    <p>
                        {aNodeMenuItemSubpages.map(({ node: nodeMenuItem }) => {
                            return <strong><a href={`/#subpage-${kebabCase(nodeMenuItem.title)}`}>{nodeMenuItem.title}</a></strong>
                        })}
                    </p>
                </div>

                {displaySectionShortDescription && aNodeMenuItemSubpages.map(({ node: nodeMenuItem }) => {
                    const nid = MenuUtils.getNodeIdByItem(nodeMenuItem);
                    const nodePage = Page.getItemByNodeId(nid, intl.locale);
                    // const nodeMenuItem = MenuUtils.getItemByNodeId(nid, intl.locale);
                    // const nodeBodyField = useParseInlineImages(nodePage) // You can use the react-html-parser in case you don't want to use dangerouslySetInnerHTML attribute

                    return (
                        <div id={`subpage-${kebabCase(nodeMenuItem.title)}`} className={`wp-block-soft4net-container-block ${kebabCase(nodeMenuItem.title)}`}>
                            <h2><Link to={nid}></Link></h2>
                            {nodePage && nodePage.field_summary && <p dangerouslySetInnerHTML={{ __html: nodePage.field_summary }}></p>}
                            {/* <p>{nodePage.body}</p> */}
                            {/* {nodeBodyField} */}
                            <p><Link to={nid} className="btn btn-secondary goto-scroll" role="button">{ t({ id: "soft4net_more" }) }</Link></p>
                        </div>
                    )
                })}
            </div>
        </React.Fragment>
    )
}

export default injectIntl(ComponentStateless)